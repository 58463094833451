import React, { useEffect, useState } from 'react';
import OpenPosition from './cmp/OpenPosition';
import ClosePosition from './cmp/ClosePosition';
import ConditionWrapper from './conditions/ConditionWrapper';
import {getAvailableRoutes, getAvailableTokens} from "token-list";

const SwapOrder = ({
                       network,
                       orderIndex,
                       tokenIn,
                       setTokenIn,
                       tokenOut,
                       setTokenOut,
                       amountIn,
                       setAmountIn,
                       conditions,
                       setConditions,
                   }) => {
    const [tokenInAll, setTokenInAll] = useState([...getAvailableTokens(network.chainId)]);
    const [tokenOutAll, setTokenOutAll] = useState([...(getAvailableRoutes(network.chainId, tokenIn) || [])]);

    // List of all possible condition types with labels
    const conditionTypes = [
        { value: 'price', label: 'Price' },
        { value: 'sma', label: 'SMA' },
        { value: 'rsi', label: 'RSI' },
        { value: 'bollinger-bands', label: 'Bollinger Bands' },
    ];

    useEffect(() => {
        setTokenInAll([...getAvailableTokens(network.chainId)]);
    }, [network]);

    useEffect(() => {
        let availableRoutes = getAvailableRoutes(network.chainId, tokenIn);
        setTokenOutAll([...availableRoutes]);
        setTokenOut(availableRoutes.values().next().value);
    }, [tokenIn]); // eslint-disable-line

    const orderLabel = orderIndex === 0 ? 'Open Position' : 'Close Position';

    const addCondition = () => {
        const selectedConditionTypes = conditions.map((cond) => cond.type);
        const availableConditionTypes = conditionTypes.filter(
            (type) => !selectedConditionTypes.includes(type.value)
        );
        if (conditions.length >= 5 || availableConditionTypes.length === 0) return;
        const newConditions = [...conditions];
        // Set operator for the previous condition if not the first one
        if (conditions.length > 0) {
            newConditions[conditions.length - 1] = {
                ...newConditions[conditions.length - 1],
                operator: 'AND',
            };
        }
        const newConditionType = availableConditionTypes[0].value;
        newConditions.push({ type: newConditionType, value: '', operator: null });
        setConditions(newConditions);
    };

    const removeCondition = (index) => {
        if (conditions.length <= 1) return; // Minimum 1 condition
        const newConditions = conditions.filter((_, i) => i !== index);
        // Remove operator from the new last condition if necessary
        if (index === conditions.length - 1 && newConditions.length > 0) {
            newConditions[newConditions.length - 1].operator = null;
        }
        setConditions(newConditions);
    };

    const updateCondition = (index, updatedCondition) => {
        const newConditions = conditions.map((condition, i) => (i === index ? updatedCondition : condition));
        setConditions(newConditions);
    };

    return (
        <div className="grid grid-cols-3 gap-3 items-center">
            <div className="col-span-3">
                <label className="font-medium text-sm">{orderLabel}</label>
            </div>
            <div className="col-span-3">
                {orderIndex === 0 ? (
                    <OpenPosition
                        tokenIn={tokenIn}
                        setTokenIn={setTokenIn}
                        tokenOut={tokenOut}
                        setTokenOut={setTokenOut}
                        tokenInAll={tokenInAll}
                        tokenOutAll={tokenOutAll}
                        amountIn={amountIn}
                        setAmountIn={setAmountIn}
                        chainId={network.chainId}
                    />
                ) : (
                    <ClosePosition
                        tokenIn={tokenIn}
                        tokenOut={tokenOut}
                        setTokenOut={setTokenOut}
                        tokenInAll={tokenInAll}
                        tokenOutAll={tokenOutAll}
                    />
                )}
            </div>
            <div className="col-span-3 mt-3">
                <label className="font-medium text-sm">Conditions</label>
            </div>
            {conditions.map((condition, index) => (
                <ConditionWrapper
                    key={index}
                    index={index}
                    condition={condition}
                    updateCondition={updateCondition}
                    removeCondition={removeCondition}
                    conditionsLength={conditions.length}
                    addCondition={addCondition}
                    tokenIn={tokenIn}
                    tokenOut={tokenOut}
                    conditions={conditions}
                    conditionTypes={conditionTypes}
                />
            ))}
        </div>
    );
};

export default SwapOrder;
