import React from 'react';

const OrderSeparator = ({addOrder, removeOrder, showAdd, showRemove}) => {
    return (
        <div className="flex items-center mb-3">
            {showAdd && (
                <span
                    className="cursor-pointer text-gray-400 hover:underline text-xs font-medium"
                    onClick={addOrder}
                >
                    +
                </span>
            )}
            {showAdd && showRemove && (
                <span className="mx-2 text-gray-400 text-xs font-medium">/</span>
            )}
            {showRemove && (
                <span
                    className="cursor-pointer text-gray-400 hover:underline text-xs font-medium"
                    onClick={removeOrder}
                >
                    —
                </span>
            )}
            <div className="flex-grow border-t border-gray-300 ml-2"></div>
        </div>
    );
};

export default OrderSeparator;
