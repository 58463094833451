import React from 'react';

const TokenSelector = ({ label, tokens, selectedToken, setSelectedToken, disabled }) => {
    return (
        <div className="flex flex-col w-[110px]">
            <label className="text-left text-xs font-medium mb-1">{label}</label>
            <div className={`flex items-center justify-center border rounded p-1 h-8 ${disabled ? 'bg-gray-100' : ''}`}>
                <img
                    src={selectedToken.logoUri}
                    alt="Token logo"
                    className="w-4 h-4 mr-1.5"
                />
                <select
                    value={selectedToken.address}
                    onChange={(e) => {
                        const token = tokens.find(t => t.address === e.target.value);
                        setSelectedToken(token);
                    }}
                    className="border-none bg-transparent cursor-pointer text-xs"
                    disabled={disabled} // Disable the select when disabled is true
                >
                    {tokens.map((t, index) => (
                        <option key={index} value={t.address}>
                            {t.symbol}
                        </option>
                    ))}
                </select>
            </div>
        </div>
    );
};

export default TokenSelector;
