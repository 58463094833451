import React, { useEffect, useState } from 'react';
import { getUsdPrice } from '../utils/LinkPriceFeed.js';
import { useAccount } from 'wagmi';
import { getERC20Balance } from '../utils/Erc20Utils.js';

const TokenAmountSelector = ({
                                 label,
                                 tokens,
                                 selectedToken,
                                 setSelectedToken,
                                 amountIn,
                                 setAmountIn,
                                 chainId,
                                 disabled,
                             }) => {
    const { address, isConnected } = useAccount();
    const [usdPrice, setUsdPrice] = useState(null);
    const [selectedTokenBalance, setSelectedTokenBalance] = useState(null); // State for storing the user's token balance

    useEffect(() => {
        const fetchUsdPrice = async () => {
            if (selectedToken && selectedToken.usdPriceOracle) {
                const price = await getUsdPrice(chainId, selectedToken);
                setUsdPrice(price);
            } else {
                setUsdPrice(null);
            }
        };

        fetchUsdPrice();
    }, [selectedToken, chainId]); // Re-fetch the price when the selected token or chain changes

    useEffect(() => {
        const fetchBalance = async () => {
            if (isConnected && address && selectedToken && chainId) {
                try {
                    const balance = await getERC20Balance(
                        chainId,
                        selectedToken.address,
                        address
                    );
                    setSelectedTokenBalance(balance);
                } catch (error) {
                    console.error('Error fetching balance:', error);
                    setSelectedTokenBalance(null);
                }
            } else {
                setSelectedTokenBalance(null);
            }
        };

        fetchBalance();
    }, [isConnected, address, selectedToken, chainId]);

    const handleInputChange = (e) => {
        const value = e.target.value;
        if (value >= 0 || value === '') {
            setAmountIn(value);
        }
    };

    const handleBalanceClick = () => {
        if (selectedTokenBalance) {
            setAmountIn(selectedTokenBalance);
        }
    };

    return (
        <div className="flex flex-col w-full">
            <div className="flex justify-between items-center mb-1">
                <label className="text-left text-xs font-medium">{label}</label>
                {isConnected && selectedTokenBalance !== null && (
                    <div
                        className="text-xs text-gray-600 cursor-pointer"
                        onClick={handleBalanceClick}
                    >
                        Balance: {parseFloat(selectedTokenBalance).toLocaleString()}
                    </div>
                )}
            </div>
            <div className="flex items-center border rounded p-1 h-8">
                <input
                    type="number"
                    value={amountIn}
                    onChange={handleInputChange}
                    className="border-none w-full mr-2"
                    placeholder="0.0"
                    min="0"
                    disabled={disabled}
                />
                <img
                    src={selectedToken.logoUri}
                    alt="Token logo"
                    className="w-4 h-4 mr-1.5"
                />
                <select
                    value={selectedToken.address}
                    onChange={(e) => {
                        const token = tokens.find((t) => t.address === e.target.value);
                        setSelectedToken(token);
                    }}
                    className="border-none bg-transparent cursor-pointer text-xs"
                    disabled={disabled}
                >
                    {tokens.map((t, index) => (
                        <option key={index} value={t.address}>
                            {t.symbol}
                        </option>
                    ))}
                </select>
            </div>
            {usdPrice && amountIn && (
                <div className="text-xs text-gray-500 mt-1">
                    ~${(amountIn * usdPrice).toFixed(2)}
                </div>
            )}
        </div>
    );
};

export default TokenAmountSelector;
