import React, { useRef, useEffect } from 'react';
import * as d3 from 'd3';
import DataClient from "../../../../utils/DataClient";

const Chart = ({ filteredData, indicators, chartWidth }) => {
    const svgRef = useRef();

    useEffect(() => {
        if (!filteredData.length || chartWidth === null) return;

        const margin = { top: 5, right: 5, bottom: 17, left: 45 };
        const width = chartWidth - margin.left - margin.right;
        const height = 270 - margin.top - margin.bottom;

        d3.select(svgRef.current).selectAll('*').remove();

        const svg = d3
            .select(svgRef.current)
            .attr('width', chartWidth)
            .attr('height', height + margin.top + margin.bottom)
            .append('g')
            .attr('transform', `translate(${margin.left},${margin.top})`);

        const xScale = d3
            .scaleTime()
            .domain(d3.extent(filteredData, (d) => d.date))
            .range([0, width]);

        const yScale = d3
            .scaleLinear()
            .domain([
                d3.min(filteredData, (d) => d.close) * 0.95,
                d3.max(filteredData, (d) => d.close) * 1.05,
            ])
            .range([height, 0]);

        svg.append('g').attr('transform', `translate(0,${height})`).call(d3.axisBottom(xScale).ticks(6));
        svg.append('g').call(d3.axisLeft(yScale));

        // Draw Bollinger Bands first, so they appear under the other lines
        if (indicators.BollingerBands) {
            const bollingerData = DataClient.calculateBollingerBands(filteredData, 5);
            appendBollingerBands(svg, bollingerData, xScale, yScale);
        }

        // Draw price line
        const line = d3
            .line()
            .x((d) => xScale(d.date))
            .y((d) => yScale(d.close));

        svg
            .append('path')
            .datum(filteredData)
            .attr('fill', 'none')
            .attr('stroke', '#4682b4')
            .attr('stroke-width', 1.5)
            .attr('d', line);

        // Draw SMA and EMA indicators on top of Bollinger Bands
        if (indicators.SMA) {
            const smaData = DataClient.calculateSMA(filteredData, 5);
            appendIndicatorLine(svg, smaData, xScale, yScale, '#ff7f0e');
        }

        if (indicators.EMA) {
            const emaData = DataClient.calculateEMA(filteredData, 5);
            appendIndicatorLine(svg, emaData, xScale, yScale, '#2ca02c');
        }
    }, [filteredData, indicators, chartWidth]);

    const appendIndicatorLine = (svg, indicatorData, xScale, yScale, color) => {
        const line = d3.line().x((d) => xScale(d.date)).y((d) => yScale(d.value));
        svg.append('path').datum(indicatorData).attr('fill', 'none').attr('stroke', color).attr('stroke-width', 1).attr('d', line);
    };

    const appendBollingerBands = (svg, data, xScale, yScale) => {
        // Bollinger Bands Area (background)
        const area = d3
            .area()
            .x((d) => xScale(d.date))
            .y0((d) => yScale(d.lower))
            .y1((d) => yScale(d.upper));

        svg.append('path').datum(data).attr('fill', 'rgba(204, 229, 223, 0.35)').attr('stroke', 'none').attr('d', area);

        // Bollinger Bands Middle Line
        const line = d3.line().x((d) => xScale(d.date)).y((d) => yScale(d.middle));
        svg.append('path').datum(data).attr('fill', 'none').attr('stroke', '#2ca02c').attr('stroke-width', 1).attr('d', line);
    };

    return <svg ref={svgRef}></svg>;
};

export default Chart;
