import * as d3 from 'd3';

class DataClient {
    // Static mock data (this could later be replaced with an API call)
    static fetchStaticData() {
        return [
            {date: new Date('2022-01-01'), close: 40000},
            {date: new Date('2022-01-02'), close: 41000},
            {date: new Date('2022-01-03'), close: 41500},
            {date: new Date('2022-01-04'), close: 40800},
            {date: new Date('2022-01-05'), close: 42000},
            {date: new Date('2022-01-06'), close: 43000},
            {date: new Date('2022-01-07'), close: 42500},
            {date: new Date('2022-01-08'), close: 44000},
            {date: new Date('2022-01-09'), close: 43500},
            {date: new Date('2022-01-10'), close: 45000},
            {date: new Date('2022-01-11'), close: 46000},
            {date: new Date('2022-01-12'), close: 45500},
            {date: new Date('2022-01-13'), close: 47000},
            {date: new Date('2022-01-14'), close: 48000},
            {date: new Date('2022-01-15'), close: 47500},
            {date: new Date('2022-01-16'), close: 49000},
            {date: new Date('2022-01-17'), close: 48500},
            {date: new Date('2022-01-18'), close: 49500},
            {date: new Date('2022-01-19'), close: 50000},
            {date: new Date('2022-01-20'), close: 51000},
            {date: new Date('2022-01-21'), close: 50500},
            {date: new Date('2022-01-22'), close: 51500},
            {date: new Date('2022-01-23'), close: 52000},
            {date: new Date('2022-01-24'), close: 53000},
            {date: new Date('2022-01-25'), close: 52500},
            {date: new Date('2022-01-26'), close: 53500},
            {date: new Date('2022-01-27'), close: 54000},
            {date: new Date('2022-01-28'), close: 55000},
            {date: new Date('2022-01-29'), close: 54500},
            {date: new Date('2022-01-30'), close: 56000},
            {date: new Date('2022-01-31'), close: 55500},
            {date: new Date('2022-02-01'), close: 56500},
            {date: new Date('2022-02-02'), close: 57000},
            {date: new Date('2022-02-03'), close: 58000},
            {date: new Date('2022-02-04'), close: 57500},
            {date: new Date('2022-02-05'), close: 59000},
            {date: new Date('2022-02-06'), close: 58500},
            {date: new Date('2022-02-07'), close: 59500},
            {date: new Date('2022-02-08'), close: 60000},
            {date: new Date('2022-02-09'), close: 61000},
            {date: new Date('2022-02-10'), close: 60500},
            {date: new Date('2022-02-11'), close: 62000},
            {date: new Date('2022-02-12'), close: 61500},
            {date: new Date('2022-02-13'), close: 63000},
            {date: new Date('2022-02-14'), close: 62500},
            {date: new Date('2022-02-15'), close: 63500},
            {date: new Date('2022-02-16'), close: 64000},
            {date: new Date('2022-02-17'), close: 65000},
            {date: new Date('2022-02-18'), close: 64500},
            {date: new Date('2022-02-19'), close: 66000},
            {date: new Date('2022-02-20'), close: 65500},
            {date: new Date('2022-02-21'), close: 67000},
            {date: new Date('2022-02-22'), close: 66500},
            {date: new Date('2022-02-23'), close: 68000},
            {date: new Date('2022-02-24'), close: 67500},
            {date: new Date('2022-02-25'), close: 69000},
            {date: new Date('2022-02-26'), close: 68500},
            {date: new Date('2022-02-27'), close: 70000},
            {date: new Date('2022-02-28'), close: 69500},
            {date: new Date('2022-03-01'), close: 71000},
            {date: new Date('2022-03-02'), close: 70500},
            {date: new Date('2022-03-03'), close: 72000},
            {date: new Date('2022-03-04'), close: 71500},
            {date: new Date('2022-03-05'), close: 73000},
            {date: new Date('2022-03-06'), close: 72500},
            {date: new Date('2022-03-07'), close: 74000},
            {date: new Date('2022-03-08'), close: 73500},
            {date: new Date('2022-03-09'), close: 75000},
            {date: new Date('2022-03-10'), close: 74500},
            {date: new Date('2022-03-11'), close: 76000},
            {date: new Date('2022-03-12'), close: 75500},
            {date: new Date('2022-03-13'), close: 77000},
            {date: new Date('2022-03-14'), close: 76500},
            {date: new Date('2022-03-15'), close: 78000},
            {date: new Date('2022-03-16'), close: 77500},
            {date: new Date('2022-03-17'), close: 79000},
            {date: new Date('2022-03-18'), close: 78500},
            {date: new Date('2022-03-19'), close: 80000},
            {date: new Date('2022-03-20'), close: 79500},
            {date: new Date('2022-03-21'), close: 81000},
            {date: new Date('2022-03-22'), close: 80500},
            {date: new Date('2022-03-23'), close: 82000},
            {date: new Date('2022-03-24'), close: 81500},
            {date: new Date('2022-03-25'), close: 83000},
            {date: new Date('2022-03-26'), close: 82500},
            {date: new Date('2022-03-27'), close: 84000},
            {date: new Date('2022-03-28'), close: 83500},
            {date: new Date('2022-03-29'), close: 85000},
            {date: new Date('2022-03-30'), close: 84500},
        ];
    }

    // Filter data based on selected range
    static filterDataByRange(data, selectedRange) {
        const endDate = d3.max(data, (d) => d.date);
        let startDate;

        switch (selectedRange) {
            case '1W':
                startDate = d3.timeWeek.offset(endDate, -1);
                break;
            case '1M':
                startDate = d3.timeMonth.offset(endDate, -1);
                break;
            case '3M':
                startDate = d3.timeMonth.offset(endDate, -3);
                break;
            case '6M':
                startDate = d3.timeMonth.offset(endDate, -6);
                break;
            case '1Y':
                startDate = d3.timeYear.offset(endDate, -1);
                break;
            default:
                startDate = d3.timeMonth.offset(endDate, -1);
        }

        return data.filter((d) => d.date >= startDate && d.date <= endDate);
    }

    // Calculate SMA
    static calculateSMA(data, windowSize) {
        return data.map((d, idx, arr) => {
            if (idx < windowSize - 1) return null;
            const slice = arr.slice(idx - windowSize + 1, idx + 1);
            const sum = slice.reduce((acc, val) => acc + val.close, 0);
            return { date: d.date, value: sum / windowSize };
        }).filter((d) => d);
    }

    // Calculate EMA
    static calculateEMA(data, windowSize) {
        const k = 2 / (windowSize + 1);
        const emaArray = [];
        data.forEach((d, idx) => {
            if (idx === 0) {
                emaArray.push({ date: d.date, value: d.close });
            } else {
                const prevEma = emaArray[idx - 1].value;
                const ema = d.close * k + prevEma * (1 - k);
                emaArray.push({ date: d.date, value: ema });
            }
        });
        return emaArray.slice(windowSize - 1);
    }

    // Calculate Bollinger Bands
    static calculateBollingerBands(data, windowSize) {
        const bollingerData = [];
        for (let i = windowSize - 1; i < data.length; i++) {
            const slice = data.slice(i - windowSize + 1, i + 1);
            const mean = slice.reduce((acc, val) => acc + val.close, 0) / windowSize;
            const stdDev = Math.sqrt(
                slice.reduce((acc, val) => acc + Math.pow(val.close - mean, 2), 0) / windowSize
            );
            bollingerData.push({
                date: data[i].date,
                middle: mean,
                upper: mean + 2 * stdDev,
                lower: mean - 2 * stdDev,
            });
        }
        return bollingerData;
    }
}

export default DataClient;
