import React from 'react';
import TargetPriceInput from '../../../../../components/TargetPriceInput';

const PriceConditionInput = ({ condition, setCondition, selectedTokenIn, selectedTokenOut }) => {
    const handlePriceChange = (price) => {
        setCondition({ ...condition, value: price });
    };

    return (
        <TargetPriceInput
            price={condition.value || ''}
            setPrice={handlePriceChange}
            selectedTokenIn={selectedTokenIn}
            selectedTokenOut={selectedTokenOut}
        />
    );
};

export default PriceConditionInput;
