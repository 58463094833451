import React from 'react';

import BaseLayout from './components/BaseLayout';
import CreateLimitOrderPage from './pages/LimitOrder/LimitOrderPage';
import {QueryClient, QueryClientProvider} from '@tanstack/react-query'
import {WagmiProvider} from 'wagmi'
import {config} from './connectors/WagmiConfig'

const queryClient = new QueryClient()

const App = () => (
    <WagmiProvider config={config}>
        <QueryClientProvider client={queryClient}>
            <BaseLayout>
                <CreateLimitOrderPage/>
            </BaseLayout>
        </QueryClientProvider>
    </WagmiProvider>
);

export default App;
