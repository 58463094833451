const OrderClient = {
    getActiveOrders: () => {
        return [
            {
                orderId: '1',
                chainId: 42161,
                tokenPair: 'USDC/WBTC',
                amountIn: '30000',
                status: 'Open',
                createdDate: '2024-10-10'
            },
            {
                orderId: '2',
                chainId: 10,
                tokenPair: 'ETH/WBTC',
                amountIn: '31000',
                status: 'Open',
                createdDate: '2024-10-11'
            },
        ];
    },
    getOrderHistory: () => {
        return [
            {
                orderId: '1',
                chainId: 10,
                tokenPair: 'USDC/WBTC',
                amountIn: '29500',
                status: 'Closed',
                createdDate: '2024-09-20'
            },
            {
                orderId: '2',
                chainId: 42161,
                tokenPair: 'ETH/WBTC',
                amountIn: '29000',
                status: 'Closed',
                createdDate: '2024-09-15'
            },
        ];
    }
};

export default OrderClient;
