const Card = ({ children, className = "", name, active = true }) => {
    return (
        <div
            className={`p-5 rounded-lg shadow-md border border-gray-300 ${active ? "bg-white" : "bg-gray-100"} ${className}`}
        >
            {name && <h2 className="text-lg font-medium mb-4">{name}</h2>}
            {children}
        </div>
    );
};

export default Card;
