import TokenSelector from "../../../../../components/TokenSelector";
import React from "react";

const ClosePosition = ({
                          tokenIn,
                          tokenOut,
                          setTokenOut,
                          tokenInAll,
                          tokenOutAll,
                      }) => {
    return (
        <div className="flex items-center">
            <TokenSelector
                label="You Pay"
                tokens={tokenInAll}
                selectedToken={tokenIn}
                disabled={true}
            />
            <span className="text-md text-center m-4">→</span>
            <TokenSelector
                label={"You Get"}
                tokens={tokenOutAll}
                selectedToken={tokenOut}
                setSelectedToken={setTokenOut}
            />
        </div>
    );
}

export default ClosePosition;