import React from 'react';
import {useAccount, useConnect, useDisconnect} from 'wagmi';
import {shortenAddress} from "../utils/Format";

const METAMASK_ICON_URL = 'https://cdn.iconscout.com/icon/free/png-512/free-metamask-logo-icon-download-in-svg-png-gif-file-formats--browser-extension-chrome-logos-icons-2261817.png';
const WALLETCONNECT_ICON_URL = 'https://raw.githubusercontent.com/WalletConnect/walletconnect-assets/refs/heads/master/Logo/White/Logo.png';
const DISCONNECT_ICON_URL = 'https://www.svgrepo.com/show/309877/plug-disconnected.svg';

const BaseLayout = ({children}) => {
    const {isConnected, address} = useAccount();
    const {disconnect} = useDisconnect();
    const {connectors, connect} = useConnect();

    return (
        <div className="min-h-screen bg-gray-100">
            <nav className="bg-white shadow p-3">
                <div className="container mx-auto flex justify-between items-center">
                    <h2 className="text-2xl font-bold">DEX Standard</h2>
                    <div>
                        {isConnected ? (
                            <div className="flex items-center space-x-2">
                                <p className="border px-4 py-2 rounded-lg shadow text-gray-800">{shortenAddress(address)}</p>
                                <button
                                    className="border px-4 py-2 rounded-lg shadow hover:bg-red-500"
                                    onClick={() => disconnect()}
                                >
                                    <img src={DISCONNECT_ICON_URL} alt="Disconnect" className="w-6 h-6"/>
                                </button>
                            </div>
                        ) : (
                            <div className="flex items-center space-x-4">
                                {connectors.map((connector) => (
                                    <button
                                        className="flex items-center bg-blue-500 text-white px-4 py-2 rounded-lg shadow hover:bg-blue-600 ml-2"
                                        key={connector.id}
                                        onClick={() => connect({connector})}
                                    >
                                        {connector.name === 'MetaMask' && (
                                            <img src={METAMASK_ICON_URL} alt="MetaMask" className="w-6 h-6 mr-2"/>
                                        )}
                                        {connector.name === 'WalletConnect' && (
                                            <img src={WALLETCONNECT_ICON_URL} alt="WalletConnect"
                                                 className="w-6 h-6 mr-2"/>
                                        )}
                                        {connector.name}
                                    </button>
                                ))}
                            </div>
                        )}
                    </div>
                </div>
            </nav>

            <main className="container mx-auto py-2">
                {children}
            </main>
        </div>
    );
};

export default BaseLayout;
