import React, { useState } from 'react';
import ChartLayout from './cmp/ChartLayout';
import OrderList from './cmp/OrderList';
import LimitOrderForm from './cmp/LimitOrderForm';

const LimitOrderPage = () => {
    const [selectedTokenPair, setSelectedTokenPair] = useState(null);

    return (
        <div className="flex justify-between mx-auto">
            <div className="w-2/3">
                <ChartLayout tokenPair={selectedTokenPair} />
                <div className="my-2">
                    <OrderList />
                </div>
            </div>
            <div className="w-1/3 ml-2">
                <LimitOrderForm setSelectedTokenPair={setSelectedTokenPair} />
            </div>
        </div>
    );
};

export default LimitOrderPage;
