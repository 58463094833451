import React, { useState } from 'react';

const conditionType = [
    "tokenIn/tokenOut",
    "tokenOut/tokenIn",
    "tokenIn/usd",
    "tokenOut/usd",
];

const TargetPriceInput = ({ price, setPrice, selectedTokenIn, selectedTokenOut }) => {
    const [selectedCondition, setSelectedCondition] = useState(conditionType[0]);

    const handleConditionChange = (e) => {
        setSelectedCondition(e.target.value);
    };

    const getHeaderLabel = () => {
        const tokenInSymbol = selectedTokenIn.symbol;
        const tokenOutSymbol = selectedTokenOut.symbol;

        if (selectedCondition === "tokenIn/tokenOut") return `${tokenOutSymbol} price cross`;
        if (selectedCondition === "tokenOut/tokenIn") return `${tokenInSymbol} price cross`;
        if (selectedCondition === "tokenIn/usd") return `${tokenInSymbol} price cross`;
        if (selectedCondition === "tokenOut/usd") return `${tokenOutSymbol} price cross`;
    };

    const getDropdownLabel = (type) => {
        if (type === "tokenIn/tokenOut") return `${selectedTokenIn.symbol} / ${selectedTokenOut.symbol}`;
        if (type === "tokenOut/tokenIn") return `${selectedTokenOut.symbol} / ${selectedTokenIn.symbol}`;
        if (type === "tokenIn/usd") return `USD / ${selectedTokenIn.symbol}`;
        if (type === "tokenOut/usd") return `USD / ${selectedTokenOut.symbol}`;
    };

    const getIconImg = (uri, alt) => {
        return (
            <img
                src={uri}
                alt={alt}
                className="w-4 h-4 mr-2"
            />
        );
    };

    const getIcon = (type) => {
        if (type === "tokenIn/tokenOut") return getIconImg(selectedTokenIn.logoUri, selectedTokenIn.symbol);
        if (type === "tokenOut/tokenIn") return getIconImg(selectedTokenOut.logoUri, selectedTokenOut.symbol);
        if (type === "tokenIn/usd" || "tokenOut/usd")
            return getIconImg("https://cdn-icons-png.flaticon.com/256/262/262280.png", "USD");
    };

    return (
        <div className="flex flex-col">
            <label className="text-left text-xs font-medium mb-1">{getHeaderLabel()}</label>
            <div className="flex items-center border rounded p-1 h-8">
                <input
                    type="number"
                    value={price}
                    onChange={(e) => setPrice(e.target.value)}
                    className="border-none w-full mr-1"
                    placeholder="0.0"
                    min="0"
                />

                {getIcon(selectedCondition)}
                <select
                    value={selectedCondition}
                    onChange={handleConditionChange}
                    className="border-none bg-transparent cursor-pointer text-xs"
                >
                    {conditionType.map((type) => (
                        <option key={type} value={type}>
                            {getDropdownLabel(type)}
                        </option>
                    ))}
                </select>
            </div>
        </div>
    );
};

export default TargetPriceInput;
