import React, { useEffect } from 'react';

const SmaConditionInput = ({ condition, setCondition }) => {
    const periodOptions = [10, 20, 50];

    const intervalOptions = [
        { label: '15 minutes', value: '15m' },
        { label: '30 minutes', value: '30m' },
        { label: '1 hour', value: '1h' },
        { label: '4 hours', value: '4h' },
        { label: '12 hours', value: '12h' },
        { label: '1 day', value: '1d' },
        { label: '3 days', value: '3d' },
        { label: '7 days', value: '7d' }
    ];

    useEffect(() => {
        if (!condition.period) {
            setCondition({ ...condition, period: periodOptions[0] });
        }
        if (!condition.interval) {
            setCondition({ ...condition, interval: intervalOptions[0].value });
        }
        if (!condition.threshold) {
            setCondition({ ...condition, threshold: 0 });
        }
    }, []); // eslint-disable-line

    const handlePeriodChange = (e) => {
        const period = e.target.value;
        setCondition({ ...condition, period });
    };

    const handleThresholdChange = (e) => {
        const threshold = e.target.value;
        setCondition({ ...condition, threshold });
    };

    const handleIntervalChange = (e) => {
        const interval = e.target.value;
        setCondition({ ...condition, interval });
    };

    return (
        <div className="grid grid-cols-3 gap-4 items-center text-xs font-medium">
            <div className="flex flex-col">
                <label className="mb-1">Sma Period</label>
                <select
                    value={condition.period || periodOptions[0]}
                    onChange={handlePeriodChange}
                    className="p-1 border rounded"
                >
                    {periodOptions.map((option) => (
                        <option key={option} value={option}>
                            {option}
                        </option>
                    ))}
                </select>
            </div>

            <div className="flex flex-col ">
                <label className=" mb-1">Chart Interval</label>
                <select
                    value={condition.interval || intervalOptions[0].value}
                    onChange={handleIntervalChange}
                    className="p-1 border rounded"
                >
                    {intervalOptions.map((option) => (
                        <option key={option.value} value={option.value}>
                            {option.label}
                        </option>
                    ))}
                </select>
            </div>

            <div className="flex flex-col">
                <label className="text-xs font-medium mb-1">Threshold</label>
                <input
                    type="number"
                    value={condition.threshold || 0}
                    onChange={handleThresholdChange}
                    className="p-1 border rounded"
                />
            </div>
        </div>
    );
};

export default SmaConditionInput;
