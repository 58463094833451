import { parseAbi } from 'viem';
import { ethers } from 'ethers';
import { getRpcProvider } from '../connectors/rpcProviders'; // Adjust the path as needed

const erc20Abi = [
  'function approve(address spender, uint256 amount) returns (bool)',
  'function balanceOf(address owner) view returns (uint256)',
  'function decimals() view returns (uint8)',
];
const parsedERC20ABI = parseAbi(erc20Abi);

export async function approveERC20(chainId, tokenAddress, spenderAddress, amount, walletClient, publicClient) {
  if (!walletClient) {
    console.error('No wallet client provided');
    throw new Error('No wallet client provided');
  }
  const [account] = await walletClient.getAddresses();

  const decimals = await publicClient.readContract({
    address: tokenAddress,
    abi: parsedERC20ABI,
    functionName: 'decimals',
    chain: chainId,
  });


  const formattedAmount = ethers.parseUnits(amount.toString(), decimals);

  try {
    const { hash } = await walletClient.writeContract({
      address: tokenAddress,
      abi: parsedERC20ABI,
      functionName: 'approve',
      args: [spenderAddress, formattedAmount],
      account,
      chain: chainId,
    });

    console.log(`Transaction hash: ${hash}`);
  } catch (error) {
    console.error('Approval failed:', error);
    throw error;
  }
}

export async function getERC20Balance(chainId, tokenAddress, ownerAddress) {
  const provider = getRpcProvider(chainId);
  if (!provider) {
    console.error('Unsupported network');
    throw new Error('Unsupported network');
  }

  const tokenContract = new ethers.Contract(tokenAddress, erc20Abi, provider);

  const [rawBalance, decimals] = await Promise.all([
    tokenContract.balanceOf(ownerAddress),
    tokenContract.decimals(),
  ]);

  return ethers.formatUnits(rawBalance, decimals);
}