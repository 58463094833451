import TokenSelector from "../../../../../components/TokenSelector";
import React from "react";
import TokenAmountSelector from "../../../../../components/TokenAmountSelector";

const OpenPosition = ({
                          tokenIn,
                          setTokenIn,
                          tokenOut,
                          setTokenOut,
                          tokenInAll,
                          tokenOutAll,
                          amountIn,
                          setAmountIn,
                          chainId
                      }) => {
    return (
        <div className="flex items-center">
            <div className="h-[55px]">
                <TokenAmountSelector
                    label="You Pay"
                    chainId={chainId}
                    tokens={tokenInAll}
                    selectedToken={tokenIn}
                    setSelectedToken={setTokenIn}
                    amountIn={amountIn}
                    setAmountIn={setAmountIn}
                />
            </div>
            <span className="text-md text-center mx-4 mt-4">→</span>
            <div className="w-[110px] h-[55px]">
                <TokenSelector
                    label={"You Get"}
                    tokens={tokenOutAll}
                    selectedToken={tokenOut}
                    setSelectedToken={setTokenOut}
                />
            </div>
        </div>
    );
}

export default OpenPosition;