import React from 'react';
import {
    useReactTable,
    getCoreRowModel,
    getPaginationRowModel,
    flexRender,
} from '@tanstack/react-table';
import {FaRegStopCircle} from 'react-icons/fa';
import {FiEdit} from 'react-icons/fi';
import {PiEyeDuotone} from 'react-icons/pi';
import {getNetworks} from "token-list";

const OrderTable = ({data, type}) => {
    const handleView = (order) => {
        console.log('View order', order);
    };

    const handleEdit = (order) => {
        console.log('Edit order', order);
    };

    const handleCancel = (order) => {
        console.log('Cancel order', order);
    };

    const iconStyles = 'w-5 h-5';

    const networks = getNetworks();

    const columns = [
        {accessorKey: 'orderId', header: 'Order ID'},
        {
            accessorKey: 'chainId',
            header: 'Network',
            cell: ({row}) => {
                const network = networks.find(
                    (n) => n.chainId === row.original.chainId
                );
                return (
                    <div className="flex items-center">
                        {network && (
                            <>
                                <img
                                    src={network.logoUri}
                                    alt={`${network.name} logo`}
                                    className="w-5 h-5 mr-2"
                                />
                                <span>{network.name}</span>
                            </>
                        )}
                    </div>
                );
            },
        },
        {accessorKey: 'tokenPair', header: 'Token Pair'},
        {accessorKey: 'amountIn', header: 'Amount In'},
        {accessorKey: 'status', header: 'Status'},
        {accessorKey: 'createdDate', header: 'Created'},
        {
            header: 'Actions',
            id: 'actions',
            cell: ({row}) => (
                <div className="flex space-x-2">
                    <button
                        onClick={() => handleView(row.original)}
                        aria-label="View Order"
                    >
                        <PiEyeDuotone className={`${iconStyles} text-blue-500`}/>
                    </button>
                    {type === 'active' && (
                        <>
                            <button
                                onClick={() => handleEdit(row.original)}
                                aria-label="Edit Order"
                            >
                                <FiEdit className={`${iconStyles} text-blue-500`}/>
                            </button>
                            <button
                                onClick={() => handleCancel(row.original)}
                                aria-label="Cancel Order"
                            >
                                <FaRegStopCircle className={`${iconStyles} text-blue-500`}/>
                            </button>
                        </>
                    )}
                </div>
            ),
        },
    ];

    const table = useReactTable({
        data,
        columns,
        getCoreRowModel: getCoreRowModel(),
        getPaginationRowModel: getPaginationRowModel(),
    });

    return (
        <div>
            <table className="w-full text-left">
                <thead>
                {table.getHeaderGroups().map((headerGroup) => (
                    <tr key={headerGroup.id}>
                        {headerGroup.headers.map((header) => (
                            <th key={header.id} className="px-4 py-2 border">
                                {header.isPlaceholder
                                    ? null
                                    : flexRender(
                                        header.column.columnDef.header,
                                        header.getContext()
                                    )}
                            </th>
                        ))}
                    </tr>
                ))}
                </thead>
                <tbody>
                {table.getRowModel().rows.map((row) => (
                    <tr key={row.id}>
                        {row.getVisibleCells().map((cell) => (
                            <td key={cell.id} className="px-4 py-2 border">
                                {flexRender(
                                    cell.column.columnDef.cell,
                                    cell.getContext()
                                )}
                            </td>
                        ))}
                    </tr>
                ))}
                </tbody>
            </table>

            <div className="pagination mt-4 flex items-center space-x-4 text-xs font-medium">
                <button
                    onClick={() => table.previousPage()}
                    disabled={!table.getCanPreviousPage()}
                >
                    Previous
                </button>
                <button
                    onClick={() => table.nextPage()}
                    disabled={!table.getCanNextPage()}
                >
                    Next
                </button>
                <span>
          Page {table.getState().pagination.pageIndex + 1} of{' '}
                    {table.getPageCount()}
        </span>
            </div>
        </div>
    );
};

export default OrderTable;
