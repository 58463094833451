import React, { useState, useEffect } from 'react';
import DataClient from '../../../utils/DataClient';
import Chart from './chart/Chart';
import Card from "../../../components/Card";

const ChartLayout = ({ tokenPair }) => {
    const [selectedRange, setSelectedRange] = useState('1M');
    const [indicators, setIndicators] = useState({
        SMA: true,
        EMA: true,
        RSI: true,
        BollingerBands: true,
    });
    const [chartWidth, setChartWidth] = useState(null);

    const staticData = DataClient.fetchStaticData();
    const filteredData = DataClient.filterDataByRange(staticData, selectedRange);

    const updateChartDimensions = () => {
        const containerWidth = document.querySelector('.chart-layout').offsetWidth;
        setChartWidth(containerWidth - 40);
    };

    useEffect(() => {
        updateChartDimensions();
        window.addEventListener('resize', updateChartDimensions);
        return () => window.removeEventListener('resize', updateChartDimensions);
    }, []);

    const handleIndicatorChange = (indicator) => {
        setIndicators({ ...indicators, [indicator]: !indicators[indicator] });
    };

    const handleRangeChange = (range) => {
        setSelectedRange(range);
    };

    const rangeOptions = ['1W', '1M'];
    const indicatorOptions = ['SMA', 'EMA', 'RSI', 'BollingerBands'];

    return (
        <Card className="w-full chart-layout" name={`${tokenPair}`}>
            <div className="flex justify-between items-center mb-4">
                <div className="flex space-x-2 text-xs font-medium">
                    {rangeOptions.map((option) => (
                        <button
                            key={option}
                            onClick={() => handleRangeChange(option)}
                            className={`px-3 py-1 rounded ${selectedRange === option ? 'bg-blue-500 text-white' : 'bg-gray-200 text-gray-700'}`}
                        >
                            {option}
                        </button>
                    ))}
                </div>
                <div className="flex space-x-4 text-xs font-medium">
                    {indicatorOptions.map((indicator) => (
                        <label key={indicator} className="flex items-center space-x-1">
                            <input
                                type="checkbox"
                                checked={indicators[indicator]}
                                onChange={() => handleIndicatorChange(indicator)}
                            />
                            <span>{indicator}</span>
                        </label>
                    ))}
                </div>
            </div>
            <Chart filteredData={filteredData} indicators={indicators} chartWidth={chartWidth} />
        </Card>
    );
};

export default ChartLayout;

