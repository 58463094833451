import React from 'react';
import {getNetworks} from "token-list";

const NetworkSelector = ({ network, setNetwork }) => {
    return (
        <div className="grid grid-cols-3 gap-1.5 items-center">
            <label className="font-medium col-span-3 text-xs">Network</label>
            {getNetworks().map((n) => (
                <button
                    key={n.chainId}
                    onClick={() => setNetwork(n)}
                    className={`border p-2 rounded flex items-center text-xs font-medium ${n === network ? 'bg-blue-500 text-white' : ''}`}
                >
                    <img
                        src={n.logoUri}
                        alt={`${n.name} logo`}
                        className="w-5 h-5 mr-1"
                    />
                    {n.name}
                </button>
            ))}
        </div>
    );
};

export default NetworkSelector;