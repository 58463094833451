import { ethers } from 'ethers';
import { arbitrum, optimism } from 'wagmi/chains';

const arbitrumProvider = new ethers.JsonRpcProvider(arbitrum.rpcUrls.default.http[0]);
const optimismProvider = new ethers.JsonRpcProvider(optimism.rpcUrls.default.http[0]);

export const getRpcProvider = (chainId) => {
    switch (chainId) {
        case 42161:
            return arbitrumProvider;
        case 10:
            return optimismProvider;
        default:
            console.error("Unsupported chainId");
            return null;
    }
}
