import { http, createConfig } from 'wagmi'
import {arbitrum, optimism} from 'wagmi/chains'
import { walletConnect } from 'wagmi/connectors'


const projectId = '3504ee2464ab9bd955f04031793fe77e'
const chains = [arbitrum, optimism];

export const config = createConfig({
    chains: chains,
    connectors: [
        walletConnect({
            projectId: projectId,
        }),
    ],
    transports: {
        [arbitrum.id]: http(),
        [optimism.id]: http(),
    },
})