import React, { useEffect } from 'react';

const BollingerBandsConditionInput = ({ condition, setCondition }) => {
    const crossoverOptions = [
        { value: 'priceAboveUpper', label: 'Price crosses above Upper Band' },
        { value: 'priceBelowLower', label: 'Price crosses below Lower Band' },
        { value: 'priceTouchesUpper', label: 'Price touches Upper Band' },
        { value: 'priceTouchesLower', label: 'Price touches Lower Band' },
        { value: 'bandSqueeze', label: 'Bollinger Band Squeeze' }
    ];

    const intervalOptions = [
        { label: '15 minutes', value: '15m' },
        { label: '30 minutes', value: '30m' },
        { label: '1 hour', value: '1h' },
        { label: '4 hours', value: '4h' },
        { label: '1 day', value: '1d' }
    ];

    useEffect(() => {
        // Set default values for interval and crossover if not already set
        if (!condition.interval || !condition.crossover) {
            setCondition({
                ...condition,
                interval: condition.interval || intervalOptions[0].value, // Default to 15 minutes
                crossover: condition.crossover || crossoverOptions[0].value // Default to 'priceAboveUpper'
            });
        }
    }, [condition, setCondition]); // eslint-disable-line

    const handleIntervalChange = (e) => {
        const interval = e.target.value;
        setCondition({ ...condition, interval });
    };

    const handleCrossoverChange = (e) => {
        const crossover = e.target.value;
        setCondition({ ...condition, crossover });
    };

    return (
        <div className="grid grid-cols-3 gap-4 items-center text-xs font-medium">
            <div className="flex flex-col">
                <label className="mb-1">Chart Interval</label>
                <select
                    value={condition.interval}
                    onChange={handleIntervalChange}
                    className="p-1 border rounded"
                >
                    {intervalOptions.map((option) => (
                        <option key={option.value} value={option.value}>
                            {option.label}
                        </option>
                    ))}
                </select>
            </div>

            <div className="flex flex-col col-span-2">
                <label className="mb-1">Condition</label>
                <select
                    value={condition.crossover}
                    onChange={handleCrossoverChange}
                    className="p-1 border rounded"
                >
                    {crossoverOptions.map((option) => (
                        <option key={option.value} value={option.value}>
                            {option.label}
                        </option>
                    ))}
                </select>
            </div>
        </div>
    );
};

export default BollingerBandsConditionInput;
