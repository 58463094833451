import React, { useState } from 'react';
import Card from '../../../components/Card';
import OrderTable from './list/OrderTable';
import OrderClient from '../../../utils/OrderClient';

const OrderList = () => {
    const [activeTab, setActiveTab] = useState('active');

    const renderOrders = () => {
        if (activeTab === 'active') {
            return (
                <div className="ml-3">
                    <OrderTable data={OrderClient.getActiveOrders()} type="active" />
                </div>
            );
        } else {
            return (
                <div className="ml-3">
                    <OrderTable data={OrderClient.getOrderHistory()} type="history" />
                </div>
            );
        }
    };

    return (
        <Card className="w-full">
            <div className="flex justify-start border-b border-gray-300 mb-3 space-x-4 text-sm font-medium">
                <button
                    onClick={() => setActiveTab('active')}
                    className={`pb-2 px-4 font-semibold ${
                        activeTab === 'active' ? 'border-b-2 border-blue-500' : ''
                    }`}
                >
                    Active
                </button>
                <button
                    onClick={() => setActiveTab('history')}
                    className={`pb-2 px-4 font-semibold ${
                        activeTab === 'history' ? 'border-b-2 border-blue-500' : ''
                    }`}
                >
                    History
                </button>
            </div>
            <div>{renderOrders()}</div>
        </Card>
    );
};

export default OrderList;
