import React, { useState } from 'react';
import Modal from '../../../components/Modal';
import { useAccount, usePublicClient, useWalletClient } from 'wagmi';
import { approveERC20 } from '../../../utils/Erc20Utils.js';

const OrderPreview = ({ isOpen, onClose, network, orders, chainId }) => {
    const { isConnected } = useAccount();
    const { data: walletClient } = useWalletClient();
    const publicClient = usePublicClient({ chainId });

    const CONTRACT_ADDRESS = '0x7Fe872A110B8F40620CE5Ea98fbb3b2A00bEbf67';

    const [orderDurationValue, setOrderDurationValue] = useState(90);
    const [orderDurationUnit, setOrderDurationUnit] = useState('days');

    const renderConditionSummary = (conditions, tokenIn, tokenOut) => {
        return conditions.map((condition, index) => {
            let summary = '';
            switch (condition.type) {
                case 'price':
                    summary = `Price crosses ${
                        condition.value || '0.0'
                    } ${tokenIn.symbol} per 1 ${tokenOut.symbol}`;
                    break;
                case 'sma':
                    summary = `SMA(${condition.period}) crosses above ${condition.threshold}`;
                    break;
                case 'rsi':
                    summary = `RSI(${condition.period}) is above ${condition.threshold}`;
                    break;
                case 'bollinger-bands':
                    summary = `Price is above the upper Bollinger Band`;
                    break;
                default:
                    summary = '';
            }
            return (
                <li key={index} className="ml-4">
                    {summary}
                    <strong>
                        {condition.operator && index < conditions.length - 1 && ` ${condition.operator}`}
                    </strong>
                </li>
            );
        });
    };

    const handleAllow = async () => {
        if (!isConnected || !walletClient) {
            console.error('Wallet is not connected or wallet client is unavailable');
            return;
        }

        if (!orders || orders.length === 0) {
            console.error('No orders available');
            return;
        }

        const tokenIn = orders[0].tokenIn;
        const tokenInSymbol = tokenIn.symbol;
        const tokenInAddress = tokenIn.address;

        try {
            const amountToApprove = orders[0].amountIn;
            await approveERC20(
                chainId,
                tokenInAddress,
                CONTRACT_ADDRESS,
                amountToApprove,
                walletClient,
                publicClient
            );
            console.log(`Approved ${tokenInSymbol} for use`);
        } catch (error) {
            console.error('Error during token approval:', error);
        }
    };

    // Function to handle order duration input changes
    const handleDurationChange = (e) => {
        const value = e.target.value;
        if (value === '' || (value >= 1 && value <= 525600)) {
            setOrderDurationValue(value);
        }
    };

    // Function to handle duration unit change
    const handleDurationUnitChange = (e) => {
        setOrderDurationUnit(e.target.value);
    };

    return (
        <Modal isOpen={isOpen} onClose={onClose}>
            <h2 className="font-bold mb-3">Order Summary</h2>

            <div className="flex items-center mb-3">
                <img
                    src={network.logoUri}
                    alt={`${network.name} logo`}
                    className="w-5 h-5 mr-2"
                />
                <span className="text-sm font-medium">{network.name}</span>
            </div>

            <ul className="space-y-4">
                {orders.map((order, index) => (
                    <li key={index}>
                        <div className="mb-2">
                            <p>
                                <strong>{index === 0 ? 'Open Position' : 'Close Position'}</strong>
                            </p>
                        </div>
                        <p>
                            Swap {order.amountIn || ''} {order.tokenIn.symbol} for {order.tokenOut.symbol}{' '}
                            when:
                        </p>
                        <ul className="list-disc pl-5 mt-1">
                            {renderConditionSummary(order.conditions, order.tokenIn, order.tokenOut)}
                        </ul>
                    </li>
                ))}
            </ul>

            <div className="mt-4">
                <p className="text-sm text-gray-600">
                    Note: This order is subject to a fee at the moment of execution equal to{' '}
                    <strong>gas fee * 0.2</strong>.
                </p>
            </div>

            <div className="mt-4">
                <label className="block text-sm font-medium mb-1">
                    Order Duration:
                </label>
                <div className="flex items-center">
                    <input
                        type="number"
                        value={orderDurationValue}
                        onChange={handleDurationChange}
                        min="1"
                        max={
                            orderDurationUnit === 'minutes'
                                ? '525600' // 365 days in minutes
                                : orderDurationUnit === 'hours'
                                    ? '8760' // 365 days in hours
                                    : '365' // 365 days
                        }
                        className="border rounded px-2 py-1 w-24 mr-2"
                    />
                    <select
                        value={orderDurationUnit}
                        onChange={handleDurationUnitChange}
                        className="border rounded px-2 py-1"
                    >
                        <option value="minutes">Minutes</option>
                        <option value="hours">Hours</option>
                        <option value="days">Days</option>
                    </select>
                </div>
                <p className="text-xs text-gray-500 mt-1">
                    Duration must be between 1 minute and 365 days.
                </p>
            </div>

            <div className="flex justify-between mt-4">
                <button
                    className="bg-blue-500 text-white py-2 px-4 rounded-lg text-sm font-medium"
                    onClick={handleAllow}
                >
                    Allow to use {orders[0].tokenIn.symbol}
                </button>
                <button
                    className="bg-gray-500 text-white py-2 px-4 rounded-lg text-sm font-medium"
                    onClick={onClose}
                >
                    Close
                </button>
            </div>
        </Modal>
    );
};

export default OrderPreview;
